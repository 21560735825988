import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import { ClientApiMap } from "@hirelingo/types";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";

const app = initializeApp({
  apiKey: "AIzaSyDuy9mKLrdlCnKLWWk0lredEUSiAS-d4cM",
  authDomain: "hirelingo.firebaseapp.com",
  projectId: "hirelingo",
  storageBucket: "hirelingo.firebasestorage.app",
  messagingSenderId: "405497164929",
  appId: "1:405497164929:web:5faa5e6ad56b46b4238980",
  measurementId: "G-DS5T8QZKF3",
});
// initEmulators();
export const firebaseAuth = getAuth(app);
export const analytics = getAnalytics();

export const signInWithEmail = (params: {
  email: string;
  password: string;
}) => {
  return FirebaseAuthentication.signInWithEmailAndPassword(params);
};

const clientApi = async <T extends keyof ClientApiMap>(params: {
  method: T;
  params: Omit<ClientApiMap[T]["params"], "method">;
}) => {
  console.log("call client api", params);
  return httpsCallable<any, ClientApiMap[T]["response"]>(
    getFunctions(),
    "clientApi"
  )({ ...params.params, method: params.method });
};

export async function login(
  params: Omit<ClientApiMap["login"]["params"], "method">
) {
  try {
    const res = await clientApi({ method: "login", params });
    const customToken =
      res.data.serverCode === 200 || res.data.serverCode === 201
        ? res.data.data.customToken
        : null;
    if (customToken) {
      await signInWithCustomToken(getAuth(), customToken);
    } else {
      console.error("error signing in", res);
    }
  } catch (err) {
    console.error("error signing in", err);
  }
}

export const getCurrentUser = async () => {
  const result = await FirebaseAuthentication.getCurrentUser();
  return result.user;
};

export const signOut = async () => {
  await FirebaseAuthentication.signOut();
};
